import chineseMessages from "ra-language-chinese";
import { mergeTranslations } from "react-admin";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...mergeTranslations(chineseMessages),
  menu: {
    ads: "茶会广告",
    accounts: "账户管理",
    subemployee: "员工管理",
    roles: "角色管理",
    employees: "员工",
    subproduct: "商品管理",
    categories: "商品类目",
    units: "商品单元",
    products: "商品",
    subinventory: "库存管理",
    inventory: "库存查询",
    inventorycount: "库存统计",
    inventorycheck: "盘点历史",
    subpurchase: "采购管理",
    suppliers: "供应商",
    purchase: "采购单",
    subpermission: "会员管理",
    members: "会员",
    levels: "会员等级",
    types: "支付方式",
  },
  common: {
    switch: "切换语言",
    account: "切换账户",
    zhname: "中文名称",
    ugname: "维文名称",
    choose: "账户选择",
    chooseaccount: "请选择账户",
    logintitle: "进销存后台管理系统",
    delTitle: "删除",
    delContent: "确定要删除吗？",
  },
  accounts: {
    mobile: "联系电话",
    member: "登陆账号（电话）",
    password: "登录密码",
    search: "手机号搜索",
    account: "账户",
    contact: "联系人",
    name: "账户名称",
    paytime: "支付时间",
    start: "使用开始",
    end: "使用结束",
    edit: "编辑账户",
    create: "新建账户",
  },
  roles: {
    name: "角色名称",
    description: "角色描述",
    displayname: "展示名称",
    editrole: "编辑角色",
    createrole: "新建角色",
  },
  employee: {
    role: "员工角色",
    password: "密码",
    edit: "编辑员工",
    create: "新建员工",
    status: "员工状态",
  },
  categories: {
    name: "类目名称",
    zhname: "类目中文名称",
    ugname: "类目维文名称",
    img: "类目图片",
    time: "创建时间",
    edit: "编辑类目",
    create: "新建类目",
  },
  units: {
    name: "单元名称",
    edit: "编辑单元",
    create: "新建单元",
  },
  products: {
    quantity: "库存",
    purchasep: "采购价（元）",
    sellp: "售价（元）",
    wholesalep: "批发价（元）",
    img: "商品图片",
    edit: "编辑商品",
    create: "新增商品",
  },
  inventory: {
    name: "商品名称",
    search: "商品名称搜索",
    barcode: "条码",
    productno: "货号",
    quantity: "库存",
  },
  inventorycount: {
    choose: "选择分类",
    asynic: "统计分析",
    categories: "按分类",
    suppliers: "按供应商",
    no: "序号",
    cate: "分类",
    quantity: "库存量",
    totalp: "可售总额",
    purchasep: "成本总额",
  },
  inventorycheck: {
    name: "盘点名称",
    start: "开始时间",
    end: "结束时间",
    details: "盘点细节",
    before: "盘点前库存",
    after: "盘点后库存",
  },
  suppliers: {
    all: "供应商名称及电话",
    name: "供应商名称",
    createtime: "创建时间",
    edit: "编辑供应商",
    create: "新建供应商",
  },
  purchase: {
    total: "原来价格(元)",
    discount: "打折价格(元)",
    realpay: "实际支付(元)",
    time: "采购时间",
    item: "采购物品",
    note: "备注",
    create: "新增采购单",
  },
  member: {
    all: "会员姓名及电话",
    name: "会员姓名",
    discountrate: "折扣率%",
    points: "积分",
    balance: "余额",
    edit: "编辑会员",
    create: "新建会员",
  },
  level: {
    name: "等级名称",
    points: "等级积分",
    zhimg: "中文图片",
    ugimg: "维文图片",
    edit: "编辑会员等级",
    create: "新建会员等级",
  },
  paytype: {
    title: "支付方式",
    edittitle: "编辑支付方式",
    createtitle: "新增支付方式",
    zhname: "中文名称",
    ugname: "维文名称",
  },
};
