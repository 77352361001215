import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button, Title, usePermissions, useTranslate } from "react-admin";
import { useCallback, useState, useEffect } from "react";

const Dashboard = () => {
  const translate = useTranslate();
  const [auth, setAuth] = useState();
  const { permissions } = usePermissions();

  const onChangeAccount = useCallback(
    (i) => {
      auth.accountId = i.account_id;
      auth.permissions = i.permissions;
      auth.type = i.account.type;
      auth.parent_account_id = i.account.parent_account_id;
      auth.fullName = `${i.account.account_name} ${i.type}`;
      localStorage.setItem("auth", JSON.stringify(auth));
      window.location.href = "/";
    },
    [auth]
  );

  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      setAuth(auth);
    }
  }, []);
  if (!auth || permissions === "admin")
    return (
      <Card>
        <Title title={translate("ra.page.dashboard")} />
      </Card>
    );

  return (
    <div style={{ width: "500px", margin: "80px auto" }}>
      <Card>
        <Title title={translate("common.choose")} />
        <CardContent>
          <div style={{ textAlign: "center", fontSize: "30px" }}>
            {translate("common.chooseaccount")}
          </div>
          {auth.user?.accounts.map((i) => {
            return (
              <div style={{ margin: "20px auto" }} key={i.account_id}>
                <Button onClick={() => onChangeAccount(i)} fullWidth>
                  <div style={{ padding: "15px", display: "flex" }}>
                    <div style={{ margin: "0 20px 0 20px" }}>
                      {i.account.account_name}
                    </div>
                    <div style={{ margin: "0 20px 0 20px", flex: "1" }}>
                      {" "}
                      {i.type}
                    </div>
                  </div>
                </Button>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
