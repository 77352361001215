import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  DashboardMenuItem,
  MenuItemLink,
  useGetPermissions,
  useTranslate,
} from "react-admin";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import CategoryIcon from "@material-ui/icons/Category";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SppliersIcon from "@material-ui/icons/SupervisedUserCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MemberIcon from "@material-ui/icons/AmpStories";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IsoIcon from "@material-ui/icons/Iso";
import SubMenu from "./SubMenu";
import { useEffect } from "react";

const Menu = ({ dense = false }) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  let manage_roles = false;
  let manage_accounts = false;
  let manage_account_employees = false;
  let manage_finance = false;
  let manage_pay_type = false;
  let manage_products = false;
  let manage_purchase = false;
  let manage_members = false;
  let manage_inventory_check = false;

  for (let i = 0; i < auth?.permissions?.length; i++) {
    if (auth.permissions[i] === "*") {
      manage_roles = true;
      manage_account_employees = true;
      manage_finance = true;
      manage_pay_type = true;
      manage_products = true;
      manage_purchase = true;
      manage_accounts = true;
      manage_members = true;
      manage_inventory_check = true;
      break;
    } else {
      switch (auth.permissions[i].name) {
        case "manage-accounts":
          manage_accounts = true;
          break;
        case "manage-roles":
          manage_roles = true;
          break;
        case "manage-members":
          manage_members = true;
          break;
        case "manage-inventory-check":
          manage_inventory_check = true;
          break;
        case "manage-finance":
          manage_finance = true;
          break;
        case "manage-account-employees":
          manage_account_employees = true;
          break;
        case "manage-purchase":
          manage_purchase = true;
          break;
        case "manage-pay-type":
          manage_pay_type = true;
          break;
        case "manage-products":
          manage_products = true;
          break;
        default:
          break;
      }
    }
  }
  if (auth?.type === 1) {
    manage_account_employees = false;
    manage_roles = false;

    manage_inventory_check = false;

    manage_pay_type = false;
  }
  if (auth?.type === 0 && auth?.parent_account_id !== null) {
    manage_accounts = false;
    manage_products = false;
  }
  const getPermissions = useGetPermissions();
  const [permissions, setPermissions] = useState();
  const translate = useTranslate();
  const [state, setState] = useState({
    menuCatalog: true,
    menuPermission: true,
    menuInventory: true,
    menuPurchase: true,
    menuRole: true,
  });
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  useEffect(() => {
    getPermissions().then((res) => {
      setPermissions(res);
    });
  }, [getPermissions]);

  if (permissions === "pending") return null;

  return (
    <div className={classes.root}>
      <DashboardMenuItem />
      {manage_accounts === true ? (
        <MenuItemLink
          to="/accounts"
          primaryText={translate("menu.accounts")}
          leftIcon={<AccountBoxIcon />}
          dense={dense}
        />
      ) : null}
      {manage_roles && manage_account_employees ? (
        <SubMenu
          isOpen={state.menuRole}
          handleToggle={() => handleToggle("menuRole")}
          name={translate("menu.subemployee")}
          icon={<HowToRegIcon />}
          dense={dense}
        >
          {manage_roles === true ? (
            <MenuItemLink
              to="/roles"
              primaryText={translate("menu.roles")}
              leftIcon={<HowToRegIcon />}
              dense={dense}
            />
          ) : null}
          {manage_account_employees === true ? (
            <MenuItemLink
              to="/account/employees"
              primaryText={translate("menu.employees")}
              leftIcon={<PersonAddIcon />}
              dense={dense}
            />
          ) : null}
        </SubMenu>
      ) : null}
      {manage_products === true ? (
        <SubMenu
          isOpen={state.menuCatalog}
          handleToggle={() => handleToggle("menuCatalog")}
          name={translate("menu.subproduct")}
          icon={<CategoryIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/product/categories"
            primaryText={translate("menu.categories")}
            leftIcon={<CategoryIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/product/units"
            primaryText={translate("menu.units")}
            leftIcon={<DataUsageIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/products"
            primaryText={translate("menu.products")}
            leftIcon={<LocalMallIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}
      {manage_inventory_check === true ? (
        <SubMenu
          isOpen={state.menuInventory}
          handleToggle={() => handleToggle("menuInventory")}
          name={translate("menu.subinventory")}
          icon={<AssignmentIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/inventory"
            primaryText={translate("menu.inventory")}
            leftIcon={<AssignmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/inventorycount"
            primaryText={translate("menu.inventorycount")}
            leftIcon={<IsoIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/inventory/checks"
            primaryText={translate("menu.inventorycheck")}
            leftIcon={<AccessTimeIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}
      {manage_purchase === true ? (
        <SubMenu
          isOpen={state.menuPurchase}
          handleToggle={() => handleToggle("menuPurchase")}
          name={translate("menu.subpurchase")}
          icon={<SppliersIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/purchase/suppliers"
            primaryText={translate("menu.suppliers")}
            leftIcon={<SppliersIcon />}
            dense={dense}
          />
          {/* <MenuItemLink
            to="/purchases"
            primaryText={translate("menu.purchase")}
            leftIcon={<ShoppingCartIcon />}
            dense={dense}
          /> */}
        </SubMenu>
      ) : null}
      {manage_members === true ? (
        <SubMenu
          isOpen={state.menuPermission}
          handleToggle={() => handleToggle("menuPermission")}
          name={translate("menu.subpermission")}
          icon={<MemberIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/members"
            primaryText={translate("menu.members")}
            leftIcon={<MemberIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/member/levels"
            primaryText={translate("menu.levels")}
            leftIcon={<DataUsageIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}
      {manage_pay_type === true ? (
        <MenuItemLink
          to="/pay/types"
          primaryText={translate("menu.types")}
          leftIcon={<AccountBalanceWalletIcon />}
          dense={dense}
        />
      ) : null}
      {auth.accountId === "2O7GkM9OPvgr63ox" ? (
        <MenuItemLink
          to="/advertisements"
          primaryText={translate("menu.ads")}
          leftIcon={<ShoppingCartIcon />}
          dense={dense}
        />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export default Menu;
