import {
  Datagrid,
  NumberField,
  SimpleList,
  TextField,
  FunctionField,
  Filter,
  TextInput,
  ListContextProvider,
  useListController,
  ExportButton,
  useTranslate,
  useQuery,
  Pagination,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const InventoryList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: "id", order: "ASC" });
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "products",
    payload: {
      pagination: { page, perPage },
      sort,
      filter: {},
    },
  });

  const InventoryFilter = () => {
    return (
      <Filter>
        <TextInput
          source="name"
          label={translate("inventory.search")}
          alwaysOn
        />
      </Filter>
    );
  };

  return (
    <ListContextProvider
      value={useListController({
        basePath: props.match?.path,
        resource: "products",
      })}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}>
          <InventoryFilter />
        </div>
        <div style={{ margin: "20px auto" }}>
          <ExportButton resource="products" />
        </div>
      </div>
      {isSmall ? (
        <SimpleList
          primaryText={() =>
            translate("common.zhname") + "  " + translate("common.ugname")
          }
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <FunctionField
            label={translate("inventory.name")}
            render={(record) => `${record.name.zh} ${record.name.ug}`}
            className={classes.ugTitle}
          />
          <FunctionField
            label={translate("categories.name")}
            render={(record) =>
              `${record.category?.data.name.zh} ${record.category?.data.name.ug}`
            }
          />
          <FunctionField
            label={translate("units.name")}
            render={(record) =>
              `${record.unit?.data.name.zh} ${record.unit?.data.name.ug}`
            }
          />
          <TextField source="barcode" label={translate("inventory.barcode")} />
          <TextField
            source="product_no"
            label={translate("inventory.productno")}
          />
          <NumberField
            source="quantity"
            label={translate("inventory.quantity")}
          />
        </Datagrid>
      )}
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={total}
      />
    </ListContextProvider>
  );
};
