import {
  Create,
  Datagrid,
  DeleteButton,
  ImageField,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  ImageInput,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";
import { imageInputFormat } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

export const LevelsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List title={translate("menu.levels")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("level.name")}
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField label={translate("common.zhname")} source="name.zh" />
          <TextField
            label={translate("common.ugname")}
            source="name.ug"
            className={classes.ugTitle}
          />
          <TextField
            source="discount_rate"
            label={translate("member.discountrate")}
          />
          <TextField label={translate("level.points")} source="level_points" />
          <ImageField
            source="image.zh"
            label={translate("level.zhimg")}
            classes={{ image: classes.img }}
          />
          <ImageField
            source="image.ug"
            label={translate("level.ugimg")}
            classes={{ image: classes.img }}
          />
          <EditButton />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle={translate("common.delTitle")}
            confirmContent={translate("common.delContent")}
          />
        </Datagrid>
      )}
    </List>
  );
};

export const LevelsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("level.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="name.zh"
          label={translate("common.zhname")}
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label={translate("common.ugname")}
          validate={required()}
        />
        <TextInput source="level_points" label={translate("level.points")} />
        <TextInput
          source="discount_rate"
          label={translate("member.discountrate")}
        />
        <ImageInput
          source="image.zh"
          label={translate("level.zhimg")}
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label={translate("level.ugimg")}
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const LevelsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("level.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="name.zh"
          label={translate("common.zhname")}
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label={translate("common.ugname")}
          validate={required()}
        />
        <TextInput source="level_points" label={translate("level.points")} />
        <TextInput
          source="discount_rate"
          label={translate("member.discountrate")}
        />
        <ImageInput
          source="image.zh"
          label={translate("level.zhimg")}
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label={translate("level.ugimg")}
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
