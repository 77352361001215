import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  SimpleList,
  TextField,
  TextInput,
  SimpleForm,
  Create,
  required,
  ReferenceArrayInput,
  CheckboxGroupInput,
  useTranslate,
  useLocale,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

import { CreateToolbar, TopBarActions } from "../components";

export const RoleList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();

  return (
    <List title={translate("menu.roles")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("roles.name")}
          secondaryText={(record) => `${record.name}`}
        />
      ) : (
        <Datagrid>
          <TextField source="name" label={translate("roles.name")} />
          <TextField
            source="description"
            label={translate("roles.description")}
          />
          <TextField
            source="display_name"
            label={translate("roles.displayname")}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};
const groupBy = (array, f) => {
  let groups = {};
  array.forEach((o) => {
    let group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
};

const optionText = (record) =>
  `${record?.description.zh} ${record?.description.ug}`;

function sortClass(sortData) {
  const sorted = groupBy(sortData, (item) => {
    return item.group.zh; // 返回需要分组的对象
  });
  return sorted;
}

const GroupInput = ({ choices, ...props }) => {
  const locale = useLocale();
  const list = sortClass(choices);
  if (list.length > 0) {
    return list.map((l, idx) => {
      return (
        <CheckboxGroupInput
          {...props}
          key={idx}
          label={locale === "ug" ? l[0].group.ug : l[0].group.zh}
          choices={l}
          optionText={optionText}
          optionValue="id"
        />
      );
    });
  }
  return null;
};

export const RoleEdit = (props) => {
  const translate = useTranslate();

  return (
    <Edit
      {...props}
      title={translate("roles.editrole")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label={translate("roles.name")}
          validate={required()}
        />
        <ReferenceArrayInput
          source="permissions.data"
          reference="permissions"
          label="权限"
          validate={required()}
          allowEmpty
          fullWidth
          format={(value) => value.map((i) => i.id ?? i)}
        >
          <GroupInput />
        </ReferenceArrayInput>

        <TextInput
          source="description"
          label={translate("roles.description")}
        />
        <TextInput
          source="display_name"
          label={translate("roles.displayname")}
        />
      </SimpleForm>
    </Edit>
  );
};

export const RoleCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("roles.createrole")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="name"
          label={translate("roles.name")}
          validate={required()}
        />

        <ReferenceArrayInput
          source="permissions"
          reference="permissions"
          label="权限"
          validate={required()}
          allowEmpty
          fullWidth
        >
          <GroupInput />
        </ReferenceArrayInput>

        <TextInput
          source="description"
          label={translate("roles.description")}
        />
        <TextInput
          source="display_name"
          label={translate("roles.displayname")}
        />
      </SimpleForm>
    </Create>
  );
};
