// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ra: {
    notification: {
      updated: "مۇۋەپپەقىيەتلىك يېڭىلاندى",
      deleted: "مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى",
      item_doesnt_exist: "بۇ مەزمۇن تېپىلمىدى",
      created: "مۇۋەپپەقىيەتلىك قوشۇلدى",
    },
    validation: {
      required: "چوقۇم تولدۇرۇسىز",
    },
    input: {
      references: {
        single_missing: "مۇناسىۋەتلىك مەزمۇن تېپىلمىدى",
      },

      image: {
        upload_single:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
        upload_several:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
      },
    },
    auth: {
      auth_check_error: "كىرىپ مەشغۇلات قىلىڭ",
      sign_in: "كىرىش",
      username: "ئەزا نامى",
      password: "پارول",
      logout: "چېكىنىش",
      user_menu: "تەڭشەك",
    },
    action: {
      remove: "ئۆچۈرۈش",
      unselect: "تاللانمىغان",
      open_menu: "ئېچىش",
      close_menu: "تاقاش",
      undo: "قايتۇرۇش",
      sort: "رەتكە تىزىش",
      undi: "قايتۇرۇش",
      back: "قايتۇرۇش",
      add: "قوشۇش",
      save: "ساقلاش",
      create: "قوشۇش",
      export: "جەدۋەل",
      edit: "تەھرىرلەش",
      refresh: "يېڭىلاش",
      delete: "ئۆچۈۈش",
      bulk_actions: "كۆپ تاللاش",
    },
    message: {
      details: "تېخىۇ كۆپ خاتالىق ئۇچۇرىنى كۆرۈش",
      error: "كۈتۈلمىگەن خاتالىق كۆرۈلدى، قايتا سىناڭ",
    },
    navigation: {
      skip_nav: "",
      prev: "ئالدىدىكىسى",
      next: "كەينىدىكىسى",
      page_rows_per_page: "ھەر بەتتە",
      page_range_info: "",
      no_results: "نەتىجىسى قۇرۇق",
    },
    page: {
      list: "تىزىملىك",
      error: "خاتالىق كۆرۈلدى",
      dashboard: "ئارقا سۇپا",
      invite: "يېڭىدىن قوشامسىز؟",
      empty: "مەزمۇن يوق",
      create: "قوشۇش",
    },
  },
  menu: {
    ads: "茶会广告",
    accounts: "ئاكونت باشقۇرۇش",
    subemployee: "خادىم باشقۇرۇش",
    roles: "ھوقۇق باشقۇرۇش",
    employees: "خادىملار",
    subproduct: "مەھسۇلات باشقۇرۇش",
    categories: "مەھسۇلات تۈرى",
    units: "مەھسۇلات بىرلىكى",
    products: "مەھسۇلاتلار",
    subinventory: "ئامبار باشقۇرۇش",
    inventory: "ئامبار قالدۇقى",
    inventorycount: " ئامبار سانىقى",
    inventorycheck: "ساناق تارىخى",
    subpurchase: "مال كىرگۈزۈش",
    suppliers: "مال تەمىنلىگۈچى",
    purchase: "مال كىرگۈزۈش تالونى",
    subpermission: "ئەزا باشقۇرۇش",
    members: "ئەزالار",
    levels: "ئەزا دەرىجىسى",
    types: "پۇل تۆلەش شەكلى",
  },
  common: {
    switch: "تىل ئالماشتۇرۇش",
    account: "ئاكونت ئالماشتۇرۇش",
    zhname: "خەنزۇچە ئىسمى",
    ugname: "ئۇيغۇرچە ئىسمى",
    choose: "ئاكونت تاللاش",
    chooseaccount: "ئاكونت تاللاش",
    logintitle: "进销存后台管理系统",
    delTitle: "删除",
    delContent: "确定要删除吗？",
  },
  accounts: {
    mobile: "يانفۇن",
    member: "كىرىش يانفۇن",
    password: "مەخپى نومۇر",
    search: "ئىزدەش",
    account: "ئاكونت",
    contact: "ئالاقىلاشقۇچى",
    name: "ئاكونت نامى",
    paytime: "پۇل تۆلىگەن ۋاقتى",
    start: "ئىشلىتىشكە باشلىغان ۋاقتى",
    end: "ئاخىرلىشىش ۋاقتى",
    edit: "ئاكونت تەھرىرلەش",
    create: "ئاكونت قوشۇش",
  },
  roles: {
    name: "ھوقۇق نامى",
    description: "ھوقۇق چۈشەندۈرۈلىشى",
    displayname: "كۆرسىتىش نامى",
    editrole: "ھوقۇق تەھرىرلەش",
    createrole: "ھوقۇق قوشۇش",
  },
  employee: {
    role: "خادىم ھوقۇقى",
    password: "مەخپى نومۇرى",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
    status: "ھالىتى",
  },
  categories: {
    name: "تۈر نامى",
    zhname: "تۈر خەنزۇچە نامى",
    ugname: "تۈر ئۇيغۇرچە نامى",
    img: "تۈر رەسىمى",
    time: "قوشۇلغان ۋاقتى",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  units: {
    name: "بىرلىك نامى",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  products: {
    quantity: "سانى",
    purchasep: "كىرگۈزۈش باھاسى",
    sellp: "سېتىش باھاسى",
    wholesalep: "توپ باھاسى",
    img: "رەسىم",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  inventory: {
    name: "مەھسۇلات نامى",
    search: "مەھسۇلات ئىزدەش",
    barcode: "تاياق كود",
    productno: "مال نومۇرى",
    quantity: "سانى",
  },
  inventorycount: {
    choose: "تۈر تاللاش",
    asynic: "ئانالىز",
    categories: "تۈر بويىچە",
    suppliers: "مال تەمىنلىگۈچى بويىچە",
    no: "نومۇرى",
    cate: "تۈرى",
    quantity: "سانى",
    totalp: "ئومومى سوممىسى",
    purchasep: "ئومومى تەننەرخى",
  },
  inventorycheck: {
    name: "ساناق تارىخ نامى",
    start: "باشلانغان ۋاقىت",
    end: "ئاخىرلاشقان ۋاقىت",
    details: "تەپسىلاتى",
    before: "ساناشتىن بۇرۇنقى سانى",
    after: "ساناشتىن كېيىنك سانى",
  },
  suppliers: {
    all: "تەمىنلىگۈچى نامى ياكى تېلېفۇنى",
    name: "تەمىنلىگۈچى نامى",
    createtime: "قوشۇلغان ۋاقتى",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  purchase: {
    total: "ئەسلى باھاسى",
    discount: "ئېتىبار قىلىنغىنى",
    realpay: "ئەمەلىي تۆلەنگىنى",
    time: "مال كىرگۈزگەن ۋاقىت",
    item: "كىرگۈزگەن مال",
    note: "ئەسكەرتىش",
    create: "قوشۇش",
  },
  member: {
    all: "ئەزا نامى ياكى تېلېفۇنى",
    name: "ئەزا ئىسمى",
    discountrate: "ئېتىبار پىرسەنتى%",
    points: "جۇغلانما",
    balance: "قالدۇق",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  level: {
    name: "دەرىجە نامى",
    points: "دەرىجە ئۆسۈش جۇغلانما نومۇرى",
    zhimg: "خەنزۇچە نامى",
    ugimg: "ئۇيغۇرچە ئىسمى",
    edit: "تەھرىرلەش",
    create: "قوشۇش",
  },
  paytype: {
    title: "پۇل تۆلەش شەكلى",
    edittitle: "تەھرىرلەش",
    createtitle: "قوشۇش",
    zhname: "خەنزۇچە نامى",
    ugname: "ئۇيغۇرچە نامى",
  },
};
