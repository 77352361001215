import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  SelectInput,
  ImageField,
  ImageInput,
  EditButton,
  DeleteButton,
  SimpleList,
  Edit,
  useTranslate,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { TopBarActions, CreateToolbar, imageInputFormat } from "../components";

const adTypes = [
  { id: 1, name: "跳出广告" },
  { id: 2, name: "首屏广告" },
];

const adUrls = [
  { id: "/pages/coupons/index", name: "商铺优惠券" },
  { id: "/pages/smcoupon/index", name: "超市优惠券" },
];

const AdsList = (props) => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List title="广告" {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.url}
          secondaryText={(record) => record.type}
        />
      ) : (
        <Datagrid>
          <ImageField
            source="image.zh"
            label="中文图片"
            classes={{ image: "w-40 h-20" }}
          />
          <ImageField
            source="image.ug"
            label="维文图片"
            classes={{ image: "w-40 h-20" }}
          />
          <TextField source="type" label="广告类型" />
          <TextField source="url" label="连接页面" />
          <EditButton />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle={translate("common.delTitle")}
            confirmContent={translate("common.delContent")}
          />
        </Datagrid>
      )}
    </List>
  );
};

const AdsCreate = (props) => {
  return (
    <Create {...props} title="新增广告" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput
          source="type"
          optionText="name"
          optionValue="id"
          label="广告类型"
          choices={adTypes}
        />
        <SelectInput
          source="url"
          optionText="name"
          optionValue="id"
          label="连接页面"
          choices={adUrls}
        />
        <ImageInput source="image.zh" label="中文图片" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
        <ImageInput source="image.ug" label="维文图片" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const AdsEdit = (props) => {
  return (
    <Edit {...props} title="编辑广告" actions={<TopBarActions />}>
      <SimpleForm>
        <SelectInput
          source="type"
          optionText="name"
          optionValue="id"
          label="广告类型"
          choices={adTypes}
        />
        <SelectInput
          source="url"
          optionText="name"
          optionValue="id"
          label="连接页面"
          choices={adUrls}
        />
        <ImageInput
          source="image.zh"
          label="中文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label="维文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export { AdsList, AdsCreate, AdsEdit };
