import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  PasswordInput,
  required,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const AccountFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        source="mobile"
        label={translate("accounts.contact")}
        alwaysOn
      />
    </Filter>
  );
};

export const AccountsList = (props) => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      title={translate("accounts.account")}
      filters={<AccountFilter />}
      //   sort={{ field: "date", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("accounts.contact")}
          secondaryText={(record) =>
            `${record.contact_name} ${record.contact_mobile}`
          }
        />
      ) : (
        <Datagrid>
          <TextField source="account_name" label={translate("accounts.name")} />
          <FunctionField
            label={translate("accounts.contact")}
            render={(record) =>
              `${record.contact_name} ${record.contact_mobile}`
            }
          />
          <DateField
            source="paid_at"
            locales="zh-CN"
            label={translate("accounts.paytime")}
          />
          <DateField
            source="trial_started_at"
            locales="zh-CN"
            label={translate("accounts.start")}
            showTime
          />
          <DateField
            source="trial_ended_at"
            locales="zh-CN"
            label={translate("accounts.end")}
            showTime
            style={{ color: "red" }}
          />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export const AccountsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("accounts.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="mobile"
          label={translate("accounts.member")}
          validate={required()}
        />
        <PasswordInput
          source="password"
          label={translate("accounts.password")}
        />
        <TextInput
          source="account_name"
          label={translate("accounts.name")}
          validate={required()}
        />
        <TextInput
          source="contact_name"
          label={translate("accounts.contact")}
        />
        <TextInput
          source="contact_mobile"
          label={translate("accounts.mobile")}
        />
      </SimpleForm>
    </Create>
  );
};

export const AccountsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("accounts.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="mobile"
          label={translate("accounts.member")}
          validate={required()}
        />
        <PasswordInput
          source="password"
          label={translate("accounts.password")}
        />
        <TextInput
          source="account_name"
          label={translate("accounts.name")}
          validate={required()}
        />
        <TextInput
          source="contact_name"
          label={translate("accounts.contact")}
        />
        <TextInput
          source="contact_mobile"
          label={translate("accounts.mobile")}
        />
      </SimpleForm>
    </Edit>
  );
};
