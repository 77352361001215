/* eslint-disable no-unused-expressions */
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  SimpleList,
  TextField,
  TextInput,
  SimpleForm,
  Create,
  required,
  useTranslate,
  PasswordInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  SelectInput,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

export const EmployeesList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();

  return (
    <List title={translate("menu.employees")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() =>
            translate("employee.role") + "   " + translate("accounts.mobile")
          }
          secondaryText={(record) => {
            return (
              <div style={{ display: "flex" }}>
                {record.roles.data.map((i) => (
                  <div key={i}>{i.name}</div>
                ))}
                <div style={{ marginLeft: "40px" }}>
                  {record.user.data.mobile}
                </div>
              </div>
            );
          }}
        />
      ) : (
        <Datagrid>
          <ArrayField source="roles.data" label={translate("employee.role")}>
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField
            source="user.data.mobile"
            label={translate("accounts.mobile")}
          />
          <TextField source="password" label={translate("employee.password")} />
          <TextField label={translate("employee.status")} source="status" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ id: searchText });

const optionText = (record) => `${record?.name}`;

export const EmployeesEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("employee.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <ReferenceArrayInput
          reference="roles"
          source="role_ids"
          label={translate("employee.role")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <SelectArrayInput optionText={optionText} optionValue="id" />
        </ReferenceArrayInput>
        <SelectInput
          label={translate("employee.status")}
          source="status"
          choices={[
            { id: "1", name: "开启状态" },
            { id: "0", name: "关闭状态" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const EmployeesCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("employee.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceArrayInput
          reference="roles"
          source="role_ids"
          label={translate("employee.role")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <SelectArrayInput optionText={optionText} optionValue="id" />
        </ReferenceArrayInput>
        <TextInput
          source="mobile"
          label={translate("accounts.mobile")}
          validate={required()}
        />
        <PasswordInput
          source="password"
          label={translate("employee.password")}
        />
        <SelectInput
          label={translate("employee.status")}
          source="status"
          choices={[
            { id: "1", name: "开启状态" },
            { id: "0", name: "关闭状态" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
