import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  SimpleList,
  TextField,
  TextInput,
  SimpleForm,
  Create,
  required,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const PayTypesList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <List title={translate("paytype.title")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() =>
            translate("paytype.zhname") + "  " + translate("paytype.ugname")
          }
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField source="name.zh" label={translate("paytype.zhname")} />
          <TextField
            source="name.ug"
            label={translate("paytype.ugname")}
            className={classes.ugTitle}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export const PayTypesEdit = (props) => {
  const translate = useTranslate();

  return (
    <Edit
      {...props}
      title={translate("paytype.edittitle")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="name.zh"
          label={translate("paytype.zhname")}
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label={translate("paytype.ugname")}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export const PayTypesCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create {...props} title={translate("paytype.createtitle")}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label={translate("paytype.zhname")} />
        <TextInput source="name.ug" label={translate("paytype.ugname")} />
      </SimpleForm>
    </Create>
  );
};
