import { Form } from "react-final-form";
import { RadioButtonGroupInput, useTranslate } from "react-admin";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useRef } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { httpClient } from "../http/index";

const InventoryCount = () => {
  const translate = useTranslate();
  const onSubmit = useRef();
  const [chart, setChart] = useState([]);

  const FormBody = ({ handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        <RadioButtonGroupInput
          defaultValue={0}
          source="type"
          label={translate("inventorycount.choose")}
          choices={[
            { id: 0, name: translate("inventorycount.categories") },
            { id: 1, name: translate("inventorycount.suppliers") },
          ]}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ margin: "20px" }}
        >
          {translate("inventorycount.asynic")}
        </Button>
      </form>
    );
  };

  onSubmit.current = async (inputData) => {
    const type = inputData?.type ? inputData.type : 0;
    const res = await httpClient.get("data/product/quantity/info", {
      params: {
        type: type,
      },
    });
    const list = res.data;
    for (let i = 0; i < list.length; i++) {
      list[i].id = i + 1;
      list[i].name =
        list[i].category === null
          ? list[i].supplier === null
            ? "无供应商"
            : list[i].supplier.contact_name
          : list[i].category.name.zh;
      list[i].库存量 = parseInt(list[i].total);
      list[i].可售总额 = parseInt(list[i].totalPrice);
      list[i].成本总额 = parseInt(list[i].purchasePrice / 100);
    }
    setChart(res.data);
  };

  const ChartList = () => {
    const columns = [
      { field: "id", headerName: translate("inventorycount.no"), width: 200 },
      {
        field: "name",
        headerName: translate("inventorycount.cate"),
        width: 200,
      },
      {
        field: "库存量",
        headerName: translate("inventorycount.quantity"),
        width: 200,
      },
      {
        field: "可售总额",
        headerName: translate("inventorycount.totalp"),
        width: 200,
      },
      {
        field: "成本总额",
        headerName: translate("inventorycount.purchasep"),
        width: 200,
      },
    ];
    const rows = chart;

    return <DataGrid rows={rows} columns={columns} pageSize={5} />;
  };

  useEffect(() => {
    onSubmit.current();
  }, []);

  return (
    <div>
      <Form onSubmit={onSubmit.current} component={FormBody} />
      <div style={{ width: "90%", height: "90%" }}>
        <ResponsiveContainer width="85%" height="85%">
          <BarChart width={1000} height={550} data={chart}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="库存量" fill="#8884d8" />
            <Bar dataKey="可售总额" fill="#82ca9d" />
            <Bar dataKey="成本总额" fill="#830" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "85%", height: "65%" }}>
        <ChartList />
      </div>
    </div>
  );
};

export default InventoryCount;
