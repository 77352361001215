import {
  Datagrid,
  List,
  SimpleList,
  TextField,
  DateField,
  ArrayField,
  NumberField,
  useTranslate,
} from "react-admin";
import { Card } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

export const InventoryCheckList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();

  const PostShow = (props) => (
    <Card>
      <ArrayField
        {...props}
        source="details.data"
        label={translate("inventorycheck.details")}
      >
        <Datagrid>
          <TextField
            label={translate("inventory.name")}
            source="product.data.name.zh"
          />
          <NumberField
            label={translate("inventorycheck.before")}
            source="original_quantity"
          />
          <NumberField
            label={translate("inventorycheck.after")}
            source="new_quantity"
          />
        </Datagrid>
      </ArrayField>
    </Card>
  );

  return (
    <List title={translate("menu.inventorycheck")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("inventorycheck.name")}
          secondaryText={(record) => `${record.name} `}
          tertiaryText={(record) => record.name}
        />
      ) : (
        <Datagrid
          expand={<PostShow />}
          isRowExpandable={(record) =>
            record.details &&
            record.details.data &&
            record.details.data.length > 0
          }
        >
          <TextField source="name" label={translate("inventorycheck.name")} />
          <DateField
            source="created_at"
            locales="zh-CN"
            label={translate("inventorycheck.start")}
            showTime
          />
          <DateField
            source="finished_at"
            locales="zh-CN"
            label={translate("inventorycheck.end")}
            showTime
          />
        </Datagrid>
      )}
    </List>
  );
};
