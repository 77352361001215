import { Admin, Resource } from "react-admin";

import authProvider from "./auth";
import dataProvider from "./http";
import Dashboard from "./Dashboard";
import MyLayout from "./layout/Layout";
import { ProductCreate, ProductEdit, ProductsList } from "./products";
import { AccountsCreate, AccountsEdit, AccountsList } from "./accounts";
import { SuppliersList, SuppliersEdit, SuppliersCreate } from "./suppliers";
import { CategoriesList, CategoriesCreate, CategoriesEdit } from "./categories";
import { MembersCreate, MembersEdit, MembersList } from "./members";
import { LevelsCreate, LevelsEdit, LevelsList } from "./levels";
import { UnitsCreate, UnitsEdit, UnitsList } from "./units";
import { InventoryCheckList } from "./inventorycheck";
import { PurchasesList, PurchasesCreate } from "./purchases";
import { PayTypesList, PayTypesEdit, PayTypesCreate } from "./paytypes";
import { RoleList, RoleCreate, RoleEdit } from "./role";
import { EmployeesList, EmployeesEdit, EmployeesCreate } from "./employees";
import MyLoginPage from "./layout/MyLoginPage";
import i18nProvider from "./i18nProvider";
import customRoutes from "./customRoutes";
import { AdsCreate, AdsEdit, AdsList } from "./ads/Advertisments";

function App() {
  return (
    <Admin
      title=""
      loginPage={MyLoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      layout={MyLayout}
      disableTelemetry
    >
      <Resource
        name="accounts"
        options={{ label: "账户" }}
        list={AccountsList}
        create={AccountsCreate}
        edit={AccountsEdit}
      />
      <Resource
        name="roles"
        options={{ label: "角色管理" }}
        list={RoleList}
        create={RoleCreate}
        edit={RoleEdit}
      />
      <Resource
        name="account/employees"
        options={{ label: "员工" }}
        list={EmployeesList}
        create={EmployeesCreate}
        edit={EmployeesEdit}
      />
      <Resource
        name="products"
        list={ProductsList}
        edit={ProductEdit}
        create={ProductCreate}
        options={{ label: "商品" }}
      />
      <Resource
        name="purchase/suppliers"
        options={{ label: "供应商" }}
        list={SuppliersList}
        edit={SuppliersEdit}
        create={SuppliersCreate}
      />
      <Resource
        name="product/categories"
        options={{ label: "商品类目" }}
        list={CategoriesList}
        edit={CategoriesEdit}
        create={CategoriesCreate}
      />
      <Resource
        name="product/units"
        options={{ label: "商品单元" }}
        list={UnitsList}
        edit={UnitsEdit}
        create={UnitsCreate}
      />
      <Resource
        name="members"
        options={{ label: "会员" }}
        list={MembersList}
        edit={MembersEdit}
        create={MembersCreate}
      />
      <Resource
        name="member/levels"
        options={{ label: "会员等级" }}
        list={LevelsList}
        edit={LevelsEdit}
        create={LevelsCreate}
      />
      <Resource
        name="inventory/checks"
        options={{ label: "盘点历史" }}
        list={InventoryCheckList}
      />
      <Resource
        name="purchases"
        options={{ label: "采购单" }}
        list={PurchasesList}
        create={PurchasesCreate}
      />
      <Resource
        name="pay/types"
        options={{ label: "支付方式" }}
        list={PayTypesList}
        create={PayTypesCreate}
        edit={PayTypesEdit}
      />
      <Resource
        name="advertisements"
        options={{ label: "茶会广告" }}
        list={AdsList}
        create={AdsCreate}
        edit={AdsEdit}
      />
      <Resource name="users" />
      <Resource name="permissions" />
    </Admin>
  );
}

export default App;
