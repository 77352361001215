import { stringify } from "qs";
import axios from "axios";

let baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.tijaretchi.bulutbazar.com/v1/"
    : "/v1/";

let lang = () => {
  const language = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ug";
  return language;
};

const httpClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "Accept-Language": lang(),
  },
});

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const withImageResource = ["member/levels", "product/categories"];

httpClient.interceptors.request.use(
  function (config) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.access_token) {
      const accountId = auth?.accountId;
      return {
        ...config,
        url: config.url.includes("?")
          ? `${config.url}&account_id=${accountId}`
          : `${config.url}?account_id=${accountId}`,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${auth.access_token}`,
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    throw new Error(response.data.message);
  }
);

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage,
    };
    if (params.filter && Object.keys(params.filter).length > 0) {
      let tmp = Object.keys(params.filter).reduce((sum, k, idx) => {
        sum += `${k}:${params.filter[k]}`;
        if (idx > 0) {
          sum += ";";
        }
        return sum;
      }, "");
      query.search = tmp;
    }
    if (resource === "permissions") {
      query.include = "permissionList";
    }
    if (resource === "inventory/checks") {
      query.include = "details";
    }

    const url = `${resource}?${stringify(query)}`;
    return httpClient(url).then(({ data: repData }) => {
      const {
        data,
        meta: { pagination },
      } = repData;
      return {
        data: data ?? [],
        total: pagination ? pagination.total : null,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${resource}/${params.id}`).then(({ data }) => ({
      data: data?.data,
    })),

  getMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    const url = `${resource}?${stringify(query)}`;
    return httpClient(url).then(({ data }) => ({
      data: data ? data.data : [],
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage,
    };
    const url = `${resource}?${stringify(query)}`;

    return httpClient(url).then(({ data: resData }) => {
      const {
        data,
        meta: { pagination },
      } = resData;
      return {
        data: data?.data,
        total: pagination ? pagination.total : null,
      };
    });
  },

  update: async (resource, params) => {
    if (resource === "products") {
      const { images, ...others } = params.data;
      const newPictures = images.filter((p) => p.rawFile instanceof File);
      const tmp = images.filter((p) => !(p.rawFile instanceof File));
      const formerPictures = tmp.map((i) => i.src);
      return Promise.all(newPictures.map(convertFileToBase64)).then(
        (transformedNewPictures) =>
          httpClient(`${resource}/${params.id}`, {
            method: "PUT",
            data: stringify({
              ...others,
              images: [...transformedNewPictures, ...formerPictures],
            }),
          }).then(({ data }) => ({ data: data?.data }))
      );
    }

    if (withImageResource.includes(resource)) {
      const { image, ...others } = params.data;
      let tmp = {};
      if (image?.ug?.rawFile instanceof File) {
        tmp.ug_image = await convertFileToBase64(image.ug);
      }
      if (image?.zh?.rawFile instanceof File) {
        tmp.zh_image = await convertFileToBase64(image.zh);
      }
      if (image?.rawFile instanceof File) {
        tmp.image = await convertFileToBase64(image);
      }
      return httpClient(`${resource}/${params.id}`, {
        ...params,
        method: "PUT",
        data: stringify({
          ...others,
          ...tmp,
        }),
      }).then(({ data }) => ({
        data: data?.data,
      }));
    }

    if (resource.includes("roles")) {
      let tmp = {
        ...params.data,
        permissions: params.data.permissions.data,
      };
      return httpClient(`${resource}/${params.id}`, {
        method: "PUT",
        data: stringify(tmp),
      }).then(({ data }) => ({ data: data?.data }));
    }

    return httpClient(`${resource}/${params.id}`, {
      method: "PUT",
      data: stringify(params.data),
    }).then(({ data }) => ({ data: data?.data }));
  },

  updateMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "PUT",
      data: stringify(params.data),
    }).then(({ data }) => ({ data }));
  },

  create: async (resource, params) => {
    if (resource === "products") {
      const { images, ...others } = params.data;
      if (images) {
        return Promise.all(images.map(convertFileToBase64)).then(
          (base64Pictures) =>
            httpClient(resource, {
              ...params,
              method: "POST",
              data: stringify({
                ...others,
                images: base64Pictures,
              }),
            }).then(({ data }) => ({
              data: {
                ...others,
                images: base64Pictures,
                id: data.data.id,
              },
            }))
        );
      }
    }
    if (resource === "product/categories") {
      if (params.data.image) {
        return convertFileToBase64(params.data.image).then((base64Pictures) =>
          httpClient(resource, {
            ...params,
            method: "POST",
            data: stringify({
              ...params.data,
              image: base64Pictures,
            }),
          }).then(({ data }) => ({
            data: { ...params.data, id: data.data.id },
          }))
        );
      }
    }
    if (withImageResource.includes(resource)) {
      const { image, ...others } = params.data;
      if (image) {
        return Promise.all([image.ug, image.zh].map(convertFileToBase64)).then(
          (base64Pictures) =>
            httpClient(resource, {
              ...params,
              method: "POST",
              data: stringify({
                ...others,
                ug_image: base64Pictures[0],
                zh_image: base64Pictures[1],
              }),
            }).then(({ data }) => ({
              data: {
                ...others,
                ug_image: base64Pictures[0],
                zh_image: base64Pictures[1],
                id: data.data.id,
              },
            }))
        );
      }
    }
    return httpClient(resource, {
      method: "POST",
      data: stringify(params.data),
    }).then(({ data }) => ({
      data: { ...params.data, id: data.data.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data })),

  deleteMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data }));
  },
};

export { httpClient };

export default dataProvider;
