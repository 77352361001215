import {
  Datagrid,
  List,
  SimpleList,
  TextField,
  TextInput,
  SimpleForm,
  Create,
  required,
  DateInput,
  ArrayInput,
  DateField,
  ArrayField,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  useTranslate,
  useLocale,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
} from "react-admin";
import { Card, Input } from "@material-ui/core";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  create: {
    display: "flex",
    width: "100%",
  },
  simple: {
    marginRight: "20px",
  },
  sortt: {
    width: "500px",
    marginRight: "20px",
  },
}));

export const PurchasesList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  const PostShow = (props) => (
    <Card>
      <ArrayField
        {...props}
        source="details.data"
        label={translate("inventorycheck.details")}
      >
        <Datagrid>
          <TextField
            label={translate("purchase.item")}
            source="product.data.name.zh"
          />
        </Datagrid>
      </ArrayField>
    </Card>
  );

  return (
    <List title={translate("menu.purchase")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("purchase.time")}
          secondaryText={(record) => `${record.purchased_at} `}
        />
      ) : (
        <Datagrid
          expand={<PostShow />}
          // isRowExpandable={(record) =>
          //   record.details &&
          //   record.details.data &&
          //   record.details.data.length > 0
          // }
        >
          <TextField source="supplier" label={translate("menu.suppliers")} />
          <TextField source="total" label={translate("purchase.total")} />
          <TextField source="discount" label={translate("purchase.discount")} />
          <TextField
            source="real_payment"
            label={translate("purchase.realpay")}
          />
          <DateField
            source="purchased_at"
            locales="zh-CN"
            label={translate("purchase.time")}
            showTime
          />
          <TextField
            source={
              locale === "zh" ? "payType.data.name.zh" : "payType.data.name.ug"
            }
            label={translate("paytype.title")}
          />
          <TextField source="notes" label={translate("purchase.note")} />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ name: searchText });

const optionText = (record) => `${record?.name.zh} ${record?.name.ug} `;
const optionText1 = (record) =>
  `商品名:${record?.name.zh} ${record?.name.ug} 采购价格:${record?.purchase_price} 销售价格:${record?.sell_price} `;

export const PurchasesCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();

  console.log(props);
  return (
    <Create
      {...props}
      title={translate("purchase.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />} fullWidth>
        <div className={classes.create}>
          <div className={classes.simple}>
            <ReferenceInput
              reference="purchase/suppliers"
              source="suppliers_id"
              label={translate("menu.suppliers")}
              filterToQuery={filterToUser}
              validate={required()}
            >
              <AutocompleteInput optionText={optionText} optionValue="id" />
            </ReferenceInput>
          </div>

          <div className={classes.simple}>
            <DateInput
              source="purchased_at"
              label={translate("purchase.time")}
              validate={required()}
            />
          </div>

          <div className={classes.simple}>
            <TextInput
              disabled
              source="total"
              label={translate("purchase.total")}
              validate={required()}
            />
          </div>

          <div className={classes.simple}>
            <TextInput
              source="discount"
              label={translate("purchase.discount")}
              validate={required()}
            />
          </div>

          <div className={classes.simple}>
            <TextInput
              source="real_payment"
              label={translate("purchase.realpay")}
              validate={required()}
            />
          </div>
        </div>

        <ArrayInput
          source="products"
          label={translate("purchase.item")}
          validate={required()}
        >
          <SimpleFormIterator>
            <ReferenceInput
              reference="products"
              source="products_id"
              label="物品"
              filterToQuery={filterToUser}
              validate={required()}
              fullWidth
            >
              <AutocompleteInput optionText={optionText1} optionValue="id" />
            </ReferenceInput>
            <Input id="outlined-basic" label="Outlined" variant="outlined"  />
            <NumberInput source="amount" label="数量" />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput
          reference="pay/types"
          source="paytype"
          label={translate("paytype.title")}
          filterToQuery={filterToUser}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <TextInput source="notes" label={translate("purchase.note")} />
      </SimpleForm>
    </Create>
  );
};
