import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  FunctionField,
  useTranslate,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

export const MembersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();

  const MemberFilter = (props) => {
    return (
      <Filter {...props}>
        <TextInput
          source="mobile"
          label={translate("accounts.search")}
          alwaysOn
        />
      </Filter>
    );
  };

  return (
    <List
      title={translate("menu.members")}
      {...props}
      filters={<MemberFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("member.all")}
          secondaryText={(record) => `${record.name} ${record.mobile}`}
          tertiaryText={(record) => record.name}
        />
      ) : (
        <Datagrid>
          <TextField source="name" label={translate("member.name")} />
          <FunctionField
            label={translate("menu.levels")}
            render={(record) =>
              `${record.level?.data.name.zh} ${record.level?.data.name.ug}`
            }
          />
          <TextField source="mobile" label={translate("accounts.mobile")} />
          <TextField
            source="discount_rate"
            label={translate("member.discountrate")}
          />
          <TextField source="points" label={translate("member.points")} />
          <TextField source="balance" label={translate("member.balance")} />
          <EditButton />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle={translate("common.delTitle")}
            confirmContent={translate("common.delContent")}
          />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ name: searchText });

const optionText = (record) => `${record?.name.zh} ${record?.name.ug}`;

export const MembersCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("member.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="name"
          label={translate("member.name")}
          validate={required()}
        />
        <ReferenceInput
          reference="member/levels"
          source="member_level_id"
          label={translate("menu.levels")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <TextInput
          source="discount_rate"
          label={translate("member.discountrate")}
          validate={required()}
        />
        <TextInput
          source="mobile"
          label={translate("accounts.mobile")}
          validate={required()}
        />
        <TextInput source="points" label={translate("member.points")} />
        <TextInput source="balance" label={translate("member.balance")} />
      </SimpleForm>
    </Create>
  );
};

export const MembersEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("member.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label={translate("member.name")}
          validate={required()}
        />
        <ReferenceInput
          reference="member/levels"
          source="member_level_id"
          label={translate("menu.levels")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <TextInput
          source="discount_rate"
          label={translate("member.discountrate")}
          validate={required()}
        />
        <TextInput
          source="mobile"
          label={translate("accounts.mobile")}
          validate={required()}
        />
        <TextInput source="points" label={translate("member.points")} />
        <TextInput source="balance" label={translate("member.balance")} />
      </SimpleForm>
    </Edit>
  );
};
