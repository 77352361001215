export const currencyProps = {
    locales: "zh-CN",
    options: { style: "currency", currency: "CNY" },
};

export const imageInputFormat = (file) => {
    if (file.rawFile instanceof File) {
        return file;
    }
    return { src: file };
};
