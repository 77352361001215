import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  NumberField,
  Edit,
  EditButton,
  Filter,
  required,
  NumberInput,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const SuppliersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();

  const SuppliersFilter = (props) => {
    return (
      <Filter {...props}>
        <NumberInput
          source="mobile"
          label={translate("accounts.search")}
          alwaysOn
        />
      </Filter>
    );
  };

  return (
    <List
      title={translate("menu.suppliers")}
      {...props}
      filters={<SuppliersFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("suppliers.all")}
          secondaryText={(record) =>
            `${record.contact_name} ${record.contact_mobile}`
          }
        />
      ) : (
        <Datagrid>
          <TextField
            label={translate("suppliers.name")}
            source="contact_name"
          />
          <NumberField
            label={translate("accounts.mobile")}
            source="contact_mobile"
          />
          <TextField label={translate("common.zhname")} source="name.zh" />
          <TextField
            label={translate("common.ugname")}
            source="name.ug"
            className={classes.ugTitle}
          />
          <DateField
            label={translate("suppliers.createtime")}
            source="created_at"
            locales="zh-CN"
            showTime
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export const SuppliersEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("suppliers.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput source="contact_name" label={translate("suppliers.name")} />
        <NumberInput
          source="contact_mobile"
          label={translate("accounts.mobile")}
        />
        <TextInput source="name.zh" label={translate("common.zhname")} />
        <TextInput source="name.ug" label={translate("common.ugname")} />
      </SimpleForm>
    </Edit>
  );
};

export const SuppliersCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("suppliers.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="contact_name"
          label={translate("suppliers.name")}
          validate={required()}
        />
        <NumberInput
          source="contact_mobile"
          label={translate("accounts.mobile")}
          validate={required()}
        />
        <TextInput source="name.zh" label={translate("common.zhname")} />
        <TextInput source="name.ug" label={translate("common.ugname")} />
      </SimpleForm>
    </Create>
  );
};
