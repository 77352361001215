import polyglotI18nProvider from "ra-i18n-polyglot";
import { zh, ug } from "./i18n/index";
import { resolveBrowserLocale } from "ra-core";

const messages = {
  zh: zh,
  ug: ug,
};

let lang = localStorage.getItem("lang");
lang = lang === "zh" ? "zh" : "ug";
const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "zh" ? messages.zh : messages.ug),
  lang
);

export default i18nProvider;
