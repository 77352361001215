import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  NumberField,
  SimpleList,
  TextField,
  ImageField,
  TextInput,
  NumberInput,
  ImageInput,
  SimpleForm,
  Create,
  ReferenceInput,
  AutocompleteInput,
  required,
  FunctionField,
  useTranslate,
  Filter,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";
import { currencyProps } from "../constants";
import { imageInputFormat } from "../components";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

const ProductsFilter = (props) => {
  const translate = useTranslate();
  const optionText = (record) => `${record?.name?.zh} ${record?.name?.ug}`;
  const filterToUser = (searchText) => ({ name: searchText });

  return (
    <Filter {...props}>
      <TextInput source="name" label={translate("menu.products")} alwaysOn />
      <ReferenceInput
        label={translate("categories.name")}
        source="category_id"
        reference="product/categories"
        filterToQuery={filterToUser}
        alwaysOn
      >
        <AutocompleteInput optionText={optionText} optionValue="id" />
      </ReferenceInput>
    </Filter>
  );
};

export const ProductsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  const state = useLocation();
  const pcate = state.cate;
  console.log(pcate);

  return (
    <List
      {...props}
      title={translate("menu.products")}
      filters={<ProductsFilter cate={pcate} />}
      filter={pcate ? { category_id: pcate } : null}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() =>
            translate("common.zhname") + "  " + translate("common.ugname")
          }
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField source="name.zh" label={translate("common.zhname")} />
          <TextField
            source="name.ug"
            label={translate("common.ugname")}
            className={classes.ugTitle}
          />
          <FunctionField
            label={translate("categories.name")}
            render={(record) =>
              `${record.category?.data.name.zh} ${record.category?.data.name.ug}`
            }
          />
          <FunctionField
            label={translate("units.name")}
            render={(record) =>
              `${record.unit?.data.name.zh} ${record.unit?.data.name.ug}`
            }
          />
          <NumberField
            source="quantity"
            label={translate("products.quantity")}
          />
          <NumberField
            source="purchase_price"
            label={translate("products.purchasep")}
            {...currencyProps}
          />
          <NumberField
            source="sell_price"
            label={translate("products.sellp")}
            {...currencyProps}
          />
          <NumberField
            source="wholesale_price"
            label={translate("products.wholesalep")}
            {...currencyProps}
          />
          <ImageField
            source="images"
            src="url"
            label={translate("products.img")}
            classes={{ image: classes.img }}
          />
          <EditButton />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle={translate("common.delTitle")}
            confirmContent={translate("common.delContent")}
          />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ name: searchText });

const optionText = (record) => `${record?.name.zh} ${record?.name.ug}`;

export const ProductEdit = (props) => {
  const translate = useTranslate();

  return (
    <Edit
      {...props}
      title={translate("products.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput source="name.zh" label={translate("common.zhname")} />
        <TextInput source="name.ug" label={translate("common.ugname")} />
        <ReferenceInput
          reference="product/categories"
          source="category_id"
          label={translate("categories.name")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          reference="product/units"
          source="unit_id"
          label={translate("units.name")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <NumberInput source="quantity" label={translate("products.quantity")} />
        <NumberInput
          source="purchase_price"
          label={translate("products.purchasep")}
        />
        <NumberInput source="sell_price" label={translate("products.sellp")} />
        <NumberInput
          source="wholesale_price"
          label={translate("products.wholesalep")}
        />
        <ImageInput
          source="images"
          label={translate("products.img")}
          accept="image/*"
          format={imageInputFormat}
          multiple
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ProductCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      actions={<TopBarActions />}
      title={translate("products.create")}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label={translate("common.zhname")} />
        <TextInput source="name.ug" label={translate("common.ugname")} />
        <ReferenceInput
          reference="product/categories"
          source="category_id"
          label={translate("categories.name")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          reference="product/units"
          source="unit_id"
          label={translate("units.name")}
          filterToQuery={filterToUser}
          validate={required()}
        >
          <AutocompleteInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <NumberInput source="quantity" label={translate("products.quantity")} />
        <NumberInput
          source="purchase_price"
          label={translate("products.purchasep")}
        />
        <NumberInput source="sell_price" label={translate("products.sellp")} />
        <NumberInput
          source="wholesale_price"
          label={translate("products.wholesalep")}
        />
        <ImageInput
          source="images"
          label={translate("products.img")}
          accept="image/*"
          multiple
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
