import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const UnitsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List title={translate("menu.units")} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("units.name")}
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField label={translate("common.zhname")} source="name.zh" />
          <TextField
            label={translate("common.ugname")}
            source="name.ug"
            className={classes.ugTitle}
          />
          <DateField
            label={translate("categories.time")}
            source="created_at"
            locales="zh-CN"
            showTime
          />
          <EditButton />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle={translate("common.delTitle")}
            confirmContent={translate("common.delContent")}
          />
        </Datagrid>
      )}
    </List>
  );
};

export const UnitsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("units.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput source="name.zh" label={translate("common.zhname")} />
        <TextInput source="name.ug" label={translate("common.ugname")} />
      </SimpleForm>
    </Edit>
  );
};

export const UnitsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("units.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="name.zh"
          label={translate("common.zhname")}
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label={translate("common.ugname")}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
