import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Title } from "react-admin";
import { useCallback, useState, useEffect } from "react";

const AccountLogin = () => {
  const [authData, setAuth] = useState();

  const onChangeAccount = useCallback(
    (i) => {
      authData.user.account_id = i.account_id;
      localStorage.setItem("auth", JSON.stringify(authData));
      alert(`已经切换账号至${i.account.account_name}`, authData);
    },
    [authData]
  );

  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    console.log(auth);
    if (auth) {
      setAuth(auth);
    }
  }, []);

  if (!authData) return null;

  return (
    <Card>
      <Title title="概览" />
      <CardContent>
        <div style={{ textAlign: "center", fontSize: "30px" }}>请选择账户</div>
        {authData.user.accounts.map((i) => {
          return (
            <Card key={i} onClick={() => onChangeAccount(i)}>
              <div style={{ padding: "10px", display: "flex" }}>
                <div style={{ margin: "0 20px 0 20px" }}>
                  {i.account.account_name}
                </div>
                <div style={{ margin: "0 20px 0 20px", flex: "1" }}>
                  {" "}
                  {i.type}
                </div>
                <ArrowForwardIosIcon />
              </div>
            </Card>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default AccountLogin;
