import { Route } from "react-router-dom";
import { InventoryList } from "./inventory";
import InventoryCount from "./inventorycount/inventorycount";
import AccountLogin from "./accountlogin";

const arr = [
  <Route exact path="/inventory" component={InventoryList} />,
  <Route exact path="/inventorycount" component={InventoryCount} />,
  <Route exact path="/accountlogin" component={AccountLogin} />,
];

export default arr;
